import { useAnimation } from 'framer-motion';
import React, { useEffect } from 'react'
import { Instagram, Twitter, Youtube } from 'react-bootstrap-icons';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import './style.scss';

function Footer() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const variants = {
    hidden: {
      opacity: 0,
      y: 70
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1
      }
    }
  };

  const iconAnimation = {
    initial: { scale: 1 },
    whileHover: { scale: 1.1 },
    whileTap: { scale: 0.9 }
  }
  
  return (
    <motion.div id='footer' ref={ref} animate={controls} initial="hidden" variants={variants}>
        <div className='social-media-stack'>
            <motion.a href="https://www.instagram.com/dead_revolver/" className='social-media-stack--item' target='_BLANK' {...iconAnimation}><Instagram /></motion.a>
            <motion.a href="https://twitter.com/DeadRevolverM" className='social-media-stack--item' target='_BLANK' {...iconAnimation}><Twitter /></motion.a>
            <motion.a href="https://www.youtube.com/channel/UCiGU6ZjNQuLcytzVnBM71Tw/" className='social-media-stack--item' target='_BLANK' {...iconAnimation}><Youtube /></motion.a>
        </div>

        <div className="title-combo title-combo--center">
            <div className="title-back font-title font-outlined text-xxl">CONTACT</div>
            <div className="title-front font-title-swash text-2xxl text-primary">Get In Touch</div>
        </div>

        <div id="contact-btn-container">
            <motion.a href='mailto:deadrevolvermusic@gmail.com' className='btn btn-large btn-secondary' {...iconAnimation}>CONTACT</motion.a>
        </div>
    </motion.div>
  )
}

export default Footer