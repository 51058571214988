import { useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion'; 
import './style.scss';

function SkillSection({ title, number, children, ...props }) {
    const backTitleControls = useAnimation();
    const frontTitleControls = useAnimation();
    const contentControls = useAnimation();
    const [backTitle, backTitleInView] = useInView();
    const [frontTitle, frontTitleInView] = useInView();
    const [content, contentInView] = useInView();

    useEffect(() => {
        if (backTitleInView) {
            backTitleControls.start("visible");
        }
        if (frontTitleInView) {
            frontTitleControls.start("visible");
        }
        if (contentInView) {
            contentControls.start("visible");
        }
    }, [backTitleControls, backTitleInView, frontTitleControls, frontTitleInView, contentControls, contentInView]);

    const backTitleVariants = {
        hidden: {
            opacity: 0,
            y: 70
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 1,
                ease: 'easeOut'
            }
        }
    };

    const frontTitleVariants = {
        hidden: {
            opacity: 0,
            y: 0
        },
        visible: {
            opacity: 1,
            y: '-50%',
            transition: {
                duration: 1,
                delay: 0.25,
                ease: 'easeOut'
            }
        }
    };

    const contentVariants = {
        hidden: {
            opacity: 0,
            y: 70
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 1,
                delay: 0.5,
                ease: 'easeOut'
            }
        }
    };

    return (
        <motion.div className="section">
            <motion.div className="title-combo">
                <motion.div ref={backTitle} animate={backTitleControls} initial="hidden" variants={backTitleVariants} className="title-back font-title font-outlined text-xxl">{number}</motion.div>
                <motion.div ref={frontTitle} animate={frontTitleControls} initial="hidden" variants={frontTitleVariants} className="title-front font-title-swash text-2xxl text-primary">{title}</motion.div>
            </motion.div>
            <motion.div ref={content} animate={contentControls} initial="hidden" variants={contentVariants} className='section--content'>
                {children}
            </motion.div>
        </motion.div>
    )
}

export default SkillSection