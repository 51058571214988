import React, { useState } from 'react'
import './style.scss'

function Menu() {
  const [activeTab, setActiveTab] = useState('home');

  return (
    <nav className='menu'>
        <a className={`menu--item ${activeTab == 'home' ? 'active' : ''}`} onClick={() => { setActiveTab('home') }} data-scroll-to="hero">Home</a>
        <a className={`menu--item ${activeTab == 'skills' ? 'active' : ''}`} onClick={() => { setActiveTab('skills') }} data-scroll-to="skills">Skills / Projects</a>
        <a className={`menu--item ${activeTab == 'contact' ? 'active' : ''}`} onClick={() => { setActiveTab('contact') }} data-scroll-to="footer">Contact</a>
    </nav>
  )
}

export default Menu