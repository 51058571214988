import React, { useEffect, useState } from 'react'

function usePreloader(onHide = () => {}) {
    const [showPreloader, setShowPreloader] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setShowPreloader(false);
            onHide();
        }, 6000);
    }, []);

    return showPreloader;
}

export default usePreloader