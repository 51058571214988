import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import './style.scss';
import Menu from '../Menu';
import usePreloader from '../../hooks/usePreloader';

function Hero() {
    const controls = useAnimation();
    const showPreloader = usePreloader(() => {
        controls.start('visible'); 
    });
    const variants = {
        hidden: {
            opacity: 0,
            y: 70,
            transition: {
                duration: 2,
                ease: 'easeOut'
            }
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 2,
                ease: 'easeOut'
            }
        }
    }
    const transition = {
        opacity: {
            duration: 2,
            ease: 'easeOut'
        },
        y: {
            duration: 2,
            ease: 'easeOut'
        }
    };

    const containerVariants = {
        default: {
            transition: {
                staggerChildren: 0.5,
                ease: 'easeOut'
            }
        }
    };
    
    return (
        <motion.div id='hero' className='row' variants={containerVariants} animate='default' initial='default'>
            <motion.div className='col-12' initial='hidden' animate={!showPreloader ? `visible` : `hidden`} variants={variants}>
                <Menu />
            </motion.div>
            <motion.div className='col-6 col-md-12' initial='hidden' animate={!showPreloader ? `visible` : `hidden`} variants={variants} >
                <div className='hero--title'>
                    <motion.img className='hero--title-img' src='/images/title.svg' alt='Dead Revolver title image' initial='hidden' animate={!showPreloader ? `visible` : `hidden`} variants={variants} />
                </div>
                <motion.div className='hero--overview' initial='hidden' animate={controls} variants={variants}>
                    <p>I am an <strong className='highlight'>artist</strong> <span className='highlight'>/</span> <strong className='highlight'>developer</strong> <span className='highlight'>/</span> <strong className='highlight'>designer</strong> <span className='highlight'>/</span> <strong className='highlight'>aspiring musician.</strong></p>
                    <p>I have an endless love and respect for all forms of art and the artists behind them. My ultimate hope is to one day leave at least a small, positive mark on the world.</p>
                    <p>To learn, express, connect and hopefully inspire.</p>
                </motion.div>
            </motion.div>
            <motion.div className='col-6 col-md-12'>
                <div className='hero--poster'>
                    <motion.img className='hero--poster-img' src='/images/header-poster.png' alt='Neon blue skull and revolver header image with purple stars in space in the background.'  initial='hidden' animate={!showPreloader ? `visible` : `hidden`} variants={variants} />
                </div>
            </motion.div>
        </motion.div>
    )
}

export default Hero