import { useEffect, useRef } from 'react';
import './App.css';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Preloader from './components/Preloader';
import Skills from './components/Skills';
import usePreloader from './hooks/usePreloader';

function App() {
  const container = useRef(null);
  const showPreloader = usePreloader();

  const scrollToElement = (element, duration) => {
    var startingY = window.pageYOffset
    var elementY = window.pageYOffset + document.querySelector(element).getBoundingClientRect().top;
    var targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY
    var diff = targetY - startingY
    var easing = function (t) { return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1 }
    var start

    if (!diff) return

    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp
      var time = timestamp - start
      var percent = Math.min(time / duration, 1)

      percent = easing(percent)
      window.scrollTo(0, startingY + diff * percent)

      if (time < duration) {
        window.requestAnimationFrame(step)
      }
    })
  }

  useEffect(() => {
    document.querySelectorAll('[data-scroll-to]').forEach((el) => {
      el.addEventListener('click', (e) => {
        scrollToElement(`#${e.target.dataset.scrollTo}`, 1000);
      });
    });
  }, []);

  return (
    <div ref={container} className={`App ${showPreloader ? 'has-preloader' : ''}`} data-scroll-container>
      <Hero showPreloader={showPreloader} />
      <Skills />
      <Footer />

      {showPreloader && (<Preloader />)}

    </div>
  );
}

export default App;
