import { useAnimation } from 'framer-motion';
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import './style.scss'
import SkillSection from './SkillSection';
import { CameraReels, JournalArrowUp, MusicNoteBeamed, SuitSpade } from 'react-bootstrap-icons';

function Skills() {
    return (
        <motion.div id='skills'>
            {/* Pixel Art & Animation */}
            <SkillSection number='01' title='Pixel Art & Animation'>
                <p>I love pixel art and the idea of turning little, tiny squares into moving, breathing art.</p>
                <p>I created the <span className="highlight">Pixel Prototype Player</span> sprite collection for developers to use in their games as a jump off point. To create quality animations to use while building and prototyping their game, and to allow them to focus on crafting interesting gameplay.</p>
                <p>I plan to create more pixel art in the future and have a pixel art game in development, called <span className='highlight'>Parasite.</span></p>
                
                <div id='pixel-art-examples'>
                    <img className='pixel-art-example' src='/images/player-idle.gif' alt='Pixel art animation of an idle player.' />
                    <img className='pixel-art-example' src='/images/player-run.gif' alt='Pixel art animation of player running.' />
                    <img className='pixel-art-example' src='/images/player-combo.gif' alt='Pixel art animation of a sword combo attack, with four different attacks.' />
                    <img className='pixel-art-example' src='/images/player-ground-slam.gif' alt='Pixel art animation of a ground slam attack.' />
                </div>

                <iframe className='section--video' width="560" height="315" src="https://www.youtube.com/embed/t4f0SWL3naU" title="YouTube video player showing the pixel platformer player sprite pack." frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </SkillSection>

            {/* Game Development */}
            <SkillSection number='02' title='Game Development'>
                <p>Growing up, games have always been something I have been fascinated by - and especially fell in love with retro games. One of my many childhood dreams have been to create a game.</p>
                <p>Currently, I am working on a game called <span className='highlight'>Parasite</span>, which is still pretty early in development, but is a project I plan on completing.</p>
                <img className='section--screenshot' src='/images/game-dev.png' alt='Screenshot of the pixel art game I am working on, showing a moonlit level, with rain and neon signs.' />
            </SkillSection>

            {/* Web Development & Design */}
            <SkillSection number='03' title='Web Development & Design'>
                <p>Web design and development is how I started getting into development and I am really passionate about it.</p>
                <p>I have created many websites as well as some personal web apps such as the <span className='highlight'>Zephyr Project Manager</span> plugin for WordPress.</p>
                <p>Currently I have a lot of experience with <span className='highlight'>HTML, CSS, JS, React, Node, PHP, Python.</span></p>
                <p>I also have experience building native mobile apps for <span className='highlight'>Android</span> and <span className='highlight'>iOS</span> with using <span className='highlight'>Java</span> and <span className='highlight'>Swift</span> respectively.</p>
                <img className='section--screenshot' src='/images/web-dev.png' alt='Programming screenshot of web dev project' />
            </SkillSection>

            {/* Everything Else */}
            <SkillSection number='04' title='Everything Else'>
                <p className='mb-4'>There is so much to learn out there and I am constantly trying to pick up new hobbies and learn new skills. Some of my other current interests and passions are:</p>
                <div id='other-skills' className='row mb-4'>
                    <div className='col-3 col-sm-12 skill-card'>
                        <h3 className='highlight font-title-swash'>Music & Guitar</h3>
                        <p>Music is a thing I could not imagine this world without.</p>
                        <p>Music always brings inspiration, and I am constantly trying to learn and write music in the hopes of one day releasing an album.</p>
                    </div>
                    <div className='col-3 col-sm-12 skill-card'>
                        <h3 className='highlight font-title-swash'>Magic & Cardistry</h3>
                        <p>Cardistry (the art of shuffling cards) and card magic is something I am very passionate about and am constantly trying to master.</p>
                        <p>In the future I plan to release a custom deck of cards that I am designing.</p>
                    </div>
                    <div className='col-3 col-sm-12 skill-card'>
                        <h3 className='highlight font-title-swash'>Writing & Stories</h3>
                        <p>I have written many poems, short stories and songs that will probably never go anywhere - but it is my goal to one day publish or write a story.</p>
                    </div>
                    <div className='col-3 col-sm-12 skill-card'>
                        <h3 className='highlight font-title-swash'>Film & Anime</h3>
                        <p>Another one of my dreams is to one day be able to create a movie.</p>
                        <p>I have written a few screenplays and have a bunch of stories swirling around in my brain, but I am still trying to hone my craft until I am one day able to afford it.</p>
                    </div>
                </div>
            </SkillSection>
        </motion.div>
    )
}

export default Skills